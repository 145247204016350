import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { createProfile } from "../../customisableuserprofiles/src/assets";

// Customizable Area Start
import { throttle } from "lodash";
import { getStorageData, removeStorageData } from "../../../framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  isLoading:boolean;
  userProfilePicture:string;
  companyProfilePicture:string;
  userProfileDatafirstName:string;
  userProfileDatalastName:string;
  userProfileDataJobTitle:string;
  Invoices:any;
  Enquires:any;
  ApprovedEnquiries:any;
  teamMembers:any;
  notificationData:any;
  isSuspended:boolean;
  suspendedMessage:string;
  isOpenNotification: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class LandingPageWebController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getProfileAccountID: string = "";
  getInvoiceCallId:string| Message="";
  getCategoriesApiCallId:string="";
  getApprovedCategoriesApiCallId:string="";
  getTeamMemberListCallId:string="";
  getNotificationsID:string="";
  userSuspendedApiCallId:string = "";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceDataMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area End
    ];

    this.state = {
      isLoading:true,
      userProfilePicture:"",
      userProfileDatafirstName:"",
      userProfileDatalastName:"",
      userProfileDataJobTitle:"",
      Invoices:"",
      Enquires:"",
      teamMembers:"",
      ApprovedEnquiries:"",
      companyProfilePicture:"",
      notificationData: [],
      isSuspended:false,
      suspendedMessage:"",
      isOpenNotification: false,
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    
      if (apiRequestCallId === this.getProfileAccountID) {
        if (responseJson.data) {
          const { attributes } = responseJson.data;
          this.setState({
            userProfilePicture: attributes.profile_picture,
            userProfileDatafirstName: attributes.first_name,
            userProfileDatalastName: attributes.last_name,
            userProfileDataJobTitle: attributes.job_title,
            companyProfilePicture:attributes.company_logo
           
          });
          this.checkForClientAdmin(responseJson.data);
        }
      } else if (apiRequestCallId === this.getInvoiceCallId) {
     
        this.handleInvoicesMembers(responseJson)
       
      } else if (apiRequestCallId === this.getCategoriesApiCallId) {
       
        this.handleEnquiriesMembers(responseJson)
        
      }
      else if (apiRequestCallId === this.getApprovedCategoriesApiCallId) {
   
          this.setState({ApprovedEnquiries:responseJson?.inquiries?.data?.length||0})
       
      } else if (apiRequestCallId === this.getTeamMemberListCallId) {
        this.handleteamMembers(responseJson)
      }else if(apiRequestCallId === this.getNotificationsID){
        this.handleNotificationData(responseJson)
      }else if(apiRequestCallId === this.userSuspendedApiCallId) {
        this.handleIsUserSuspended(message)
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    await this.fetchProfileData();
    await this.getInvoices();
    await this.getcategoryList();
    await this.getapprovedcategoryList();
    await this.getNotificationsList();
    window.addEventListener("mousemove", this.checkIsUserIsSuspended)
  }

  async componentWillUnmount() {
    window.removeEventListener("mousemove", this.checkIsUserIsSuspended)
  }

  checkIsUserIsSuspended = throttle(async() => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": await getStorageData("authToken")
    };
    this.userSuspendedApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.suspendedUserEndPoint 
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  },4000)

  handleIsUserSuspended = (message:Message) => {
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    if(responseJson.status && responseJson.status === "suspended"){
      this.setState({isSuspended:true,suspendedMessage:responseJson.message})
      window.removeEventListener("mousemove", this.checkIsUserIsSuspended)
    }
  }

  handleGoToLoginPage = async () => {
    await removeStorageData("authToken")
    await removeStorageData("role")
    this.setState({isSuspended:false,suspendedMessage:""})
    const message: Message = new Message(getName(MessageEnum.NavigationMessage))
    message.addData(getName(MessageEnum.NavigationTargetMessage),'EmailAccountLoginBlock');
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props)
    this.send(message);
  }

  handleteamMembers(responseJson:any){
    if (responseJson.company_users) {
      this.setState({ teamMembers: responseJson.company_users?.data?.length||0});
    }
  }

  handleNotificationData(responseJson:any){
    if (responseJson?.data) {
      const sortedData = responseJson.data.sort((a:any, b:any) => 
        new Date(b.attributes.updated_at).getTime() - new Date(a.attributes.updated_at).getTime()
      );
      this.setState({ notificationData: sortedData || [], isOpenNotification : sortedData.length > 0 ? true : false});
    }
  }

  handleInvoicesMembers(responseJson:any){
    if (responseJson) {
      this.setState({ Invoices: responseJson.invoices?.Invoices?.length||0 })
      }
  }

  handleEnquiriesMembers(responseJson:any){
    if(responseJson){
      this.setState({Enquires:responseJson?.inquiries?.data?.length||0,isLoading:false})
    } 
  }

  checkForClientAdmin = (data: {attributes:{type:string}}) =>{
    if(data.attributes.type === "ClientAdmin"){
      this.getTeamMemberListApi();
    }
  }


  getInvoices = () => {
    const header = {
      "Content-Type": configJSON.ApiContentType,
      token: localStorage.getItem('authToken')
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getInvoiceCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getInvoicesEndPoint
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  fetchProfileData =  () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem('authToken')
    };

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.getProfileAccountID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getViewUserProfileEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
  getcategoryList = () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token:  localStorage.getItem('authToken')
    };
    
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
   
      this.getCategoriesApiCallId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `/inquiries?status=`
        );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
  getapprovedcategoryList = async () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token:  localStorage.getItem('authToken')
    };
    
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
   
      this.getApprovedCategoriesApiCallId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `/inquiries?status=approved`
        );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  getTeamMemberListApi = () => {

    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token:  localStorage.getItem('authToken')
    };

    const requestMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getTeamMemberListCallId = requestMsg.messageId;

    requestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getAllTeamMemberEndPoint
    );

    requestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMsg.id, requestMsg);
    return true;
  };

  getNotificationsList = async() => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token:  localStorage.getItem('authToken')
    };
    
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
   
      this.getNotificationsID = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.getNotificationsEndPoint
        );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  selectViewProfile = () => {
    let profileImageSrc;
  
    if (this.state.userProfilePicture!="No File Attached") {
      profileImageSrc =this.state.userProfilePicture;
    } else {
      profileImageSrc = createProfile; 
    }
    return profileImageSrc; 
  };
  selectViewCompanyProfile = () => {
    let profileImageSrc;
  
    if (this.state.companyProfilePicture!="No File Attached") {
      profileImageSrc =this.state.companyProfilePicture;
    } else {
      profileImageSrc = createProfile; 
    }
    return profileImageSrc; 
  };

  async refreshHome(){
    this.setState({isLoading:true})
    await this.fetchProfileData();
    await this.getInvoices();
    await this.getcategoryList();
    await this.getapprovedcategoryList();
    await this.getNotificationsList()
  }

  viewInquiry = (id: any) => {
    window.location.assign(`/Enquiries?id=${id}`);
  };

  goToCategory=()=> {
    this.props.navigation.navigate("Categoriessubcategories");
  }
  goToHelpCenterLandingPage=()=> {
    this.props.navigation.navigate("HelpCenter");
  }

  goToProfileLandingPage=()=> {
    this.props.navigation.navigate("CustomisableuserProfiles");
  }

  goToInvoicePage=()=>  {
    this.props.navigation.navigate("InvoiceBilling");
  }

  goToTeamMembersPage=()=>  {
    this.props.navigation.navigate("TeamBuilderList");
  }
  
  goToApprovedEnquires=()=>  {
    localStorage.removeItem("enqiry")
    localStorage.setItem("enqiry","approved enquiries")
    this.props.navigation.navigate("Enquiries");
  }
  goToHomeLandingPage=()=>  {
    const msg: Message = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), "LandingPage");
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }
  goToEnquiry=()=>  {
    localStorage.removeItem("enqiry")
    localStorage.setItem("enqiry","")
    this.props.navigation.navigate("Enquiries");
  }
  
  goToQuote =()=> {
    const msg: Message = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), "Categoriessubcategories");
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  goToSettingsLandingPage 
  =()=> 
  {
    const msg: Message = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), "Settings2");
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  getJobTitle = (inputName:string) => inputName.length > 38 ? `${inputName.slice(0, 38)}...` : inputName;

  formatDate(date:any) {
    const updatedAt = new Date(date);
    return `${updatedAt.getMonth() + 1}/${updatedAt.getDate()}/${updatedAt.getFullYear()}`;
  }

  getRelativeTime(date:any) {
    const updatedAt = new Date(date);
    const now = new Date();
    const timeDiff = Math.abs(now.getTime() - updatedAt.getTime());

    const hoursDiff = Math.floor(timeDiff / (1000 * 60 * 60));
    const daysDiff = Math.floor(timeDiff / (1000 * 60 * 60 * 24));

    if (hoursDiff < 1) {
      const minutesDiff = Math.floor(timeDiff / (1000 * 60));
      return `${minutesDiff}min`;
    } else if (hoursDiff < 24) {
      return `${hoursDiff}hr`;
    } else {
      return `${daysDiff}d`;
    }
  }

  handleToggleIsOpenNotification = () => {
    return this.setState({ isOpenNotification : !this.state.isOpenNotification})
  }
  // Customizable Area End
}
